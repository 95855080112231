@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.list-stripe > .list-item {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.list-stripe > .list-item + .list-item {
  border-top: 0;
}
.list-stripe > .list-item > .label {
  -webkit-box-flex: 3;
  -webkit-flex: 3 3 2%;
          flex: 3 3 2%;
}
.list-stripe > .list-item > .extra {
  -webkit-box-flex: 2;
  -webkit-flex: 2 2 1%;
          flex: 2 2 1%;
  text-align: left;
}
.valid-error {
  color: #fa755a;
  padding: 0.2rem;
}
.StripeElement {
  line-height: 0.4rem;
  padding: 0.1rem;
  /*border: 1px solid transparent;*/
  background-color: #fff;
  /*box-shadow: 0 1px 3px 0 #e6ebf1;*/
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
  border: 1px solid #f1f1f1;
  border-radius: 0.05rem;
}
.StripeElement--focus {
  /*box-shadow: 0 1px 3px 0 #cfd7df;*/
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.logo-icon[data-v-07d949d9] {
  width: 0.5rem;
  border-radius: 100em;
  overflow: hidden;
}
.logo-icon > img[data-v-07d949d9] {
  width: 100%;
  height: auto;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.container > .inner[data-v-5f8e9ef2] {
  padding: 0.3rem 0.6rem 0 0.6rem;
}
.container > .inner > .status > .icon[data-v-5f8e9ef2] {
  font-size: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
          align-items: center;
  color: #868e96;
}
.container > .inner > .status > .icon > .label[data-v-5f8e9ef2] {
  font-size: 0.4rem;
  margin-top: 0.2rem;
}
.container > .inner > .status > .order-info[data-v-5f8e9ef2] {
  margin: 0.9rem 0;
  text-align: center;
  font-size: 0.36rem;
}
.container > .inner > .status > .order-info > .price[data-v-5f8e9ef2] {
  color: #333333;
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1;
  margin-top: 0.2rem;
}
.container > .inner > .status > .order-info > .price[data-v-5f8e9ef2]:before {
  /*vertical-align: top;*/
  margin-right: 0.1rem;
}
.container > .inner > .footer[data-v-5f8e9ef2] {
  margin-bottom: 0.9rem;
}
.container > .inner > .footer > .btn[data-v-5f8e9ef2] {
  min-width: 40%;
  padding: 0.2rem;
}
@charset "UTF-8";
/**
 * Created by henian.xu on 2017/10/2.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/6/21.
 *
 */
/**
 * Created by henian.xu on 2017/7/3 0003.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/**
 * Created by henian.xu on 2017/6/20.
 *
 */
/*灰色系*/
/*主色系*/
/*辅助色*/
/*边框颜色*/
/*字体颜色*/
/**
 * Created by henian.xu on 2017/7/14 0014.
 *
 */
/*z-index variables*/
/**
 * Created by henian.xu on 2017/7/3 0003.
 * 公共混合类
 */
.logo-icon[data-v-2d66cdd0] {
  width: 0.5rem;
  border-radius: 100em;
  overflow: hidden;
}
.logo-icon > img[data-v-2d66cdd0] {
  width: 100%;
  height: auto;
}

/*# sourceMappingURL=pay.a2f36326.css.map*/