@import "@/assets/style/_index.scss";





































































































































.logo-icon {
  width: 0.5rem;
  border-radius: 100em;
  overflow: hidden;
  > img {
    width: 100%;
    height: auto;
  }
}
